import { TitanAdvertiserPerformance } from '@cognitiv/galaxy-api';
import { titan_advertiser_default } from 'products/titan/operators/advertiser/defaults';

export const titan_advertiser_performance_default: TitanAdvertiserPerformance = {
  ...titan_advertiser_default,
  spend_usd_today: 0,
  spend_usd_yesterday: 0,
  spend_usd_cumulative: 0,
  spend_usd_today_abbr_formatted: '',
  spend_usd_yesterday_abbr_formatted: '',
  spend_usd_cumulative_abbr_formatted: '',
  impressions_today: 0,
  impressions_yesterday: 0,
  impressions_year: 0,
  impressions_today_abbr_formatted: '',
  impressions_yesterday_abbr_formatted: '',
  impressions_year_abbr_formatted: '',
  bid_requests_today: 0,
  bid_requests_yesterday: 0,
  bid_requests_year: 0,
  bid_requests_today_abbr_formatted: '',
  bid_requests_yesterday_abbr_formatted: '',
  bid_requests_year_abbr_formatted: '',
  bid_rate_today: 0,
  bid_rate_yesterday: 0,
  bid_rate_year: 0,
  bid_rate_today_abbr_formatted: '',
  bid_rate_yesterday_abbr_formatted: '',
  bid_rate_year_abbr_formatted: '',
  win_rate_today: 0,
  win_rate_yesterday: 0,
  win_rate_year: 0,
  win_rate_today_abbr_formatted: '',
  win_rate_yesterday_abbr_formatted: '',
  win_rate_year_abbr_formatted: '',
  uuid: '',
};
