import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { faBookUser, faBullhorn, faChartMixed, faSquareTerminal } from '@fortawesome/pro-regular-svg-icons';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { selectSettings } from 'ducks/settings/selectors';
import { useGlobalTheme } from 'hooks/useGlobalTheme';
import { GROUP, PAGE } from 'hooks/useMenu';
import { THEME } from 'hooks/useTheme';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { TITAN_ORGANIZATION } from 'products/titan/enums';
import { HYPERION, LIBRA, ORION, SCORPIO, TITAN } from 'routes';
import { useAppSelector } from 'store/hooks';

import { MenuAction } from 'components/menu/components/MenuAction';
import { MenuGroup } from 'components/menu/components/MenuGroup';
import { MenuPage } from 'components/menu/components/MenuPage';
import { MenuSwitch } from 'components/menu/components/MenuSwitch';
import { useMenuContext } from 'components/menu/context/MenuContext';
import logoClose from 'components/menu/logo/logo-animation-close.json';
import logoOpen from 'components/menu/logo/logo-animation-open.json';
import cn from 'components/menu/Menu.module.scss';
import { PlatformSecurity } from 'components/platform-security/PlatformSecurity';

const { REACT_APP_VERSION } = process.env;

export const Menu = () => {
  const { theme } = useAppSelector(selectSettings);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const [hidden, setHidden] = useState(false);

  const { group, subgroup, is_menu_open, setMenu, getApplicationPage } = useMenuContext();

  const { switchTheme } = useGlobalTheme();

  useEffect(() => {
    setTimeout(() => setHidden(true), 300);
  }, [is_menu_open]);

  useEffect(() => {
    // DEV-NOTE: Prevent animation from jumping on first render by setting it to the end position
    if (lottieRef.current) {
      const { goToAndStop } = lottieRef.current;
      goToAndStop(is_menu_open ? 250 : 200);
    }
  }, []);

  return (
    <nav className={classNames(cn.menu, { [cn.open]: is_menu_open, [cn.hidden]: hidden })}>
      <Link className={classNames(cn.image, { [cn.open]: is_menu_open })} to={`${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}`}>
        <Lottie lottieRef={lottieRef} animationData={is_menu_open ? logoOpen : logoClose} loop={0} style={{ width: 200, height: 50 }} />
      </Link>
      <div className={cn.toggle}>
        <MenuAction
          onClick={() => {
            setHidden(false);
            setMenu(!is_menu_open);
          }}
          label={is_menu_open ? 'Collapse Menu' : 'Expand Menu'}
          icon={faBars}
        />
      </div>
      <div className={classNames(cn.grow, cn.mainMenuItems)}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <div className={cn.position} style={{ height, width }}>
                <MenuGroup
                  label="Advertisers"
                  to={`${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}`}
                  icon={faBookUser}
                  selected={group === GROUP.ADVERTISERS}
                />
                <PlatformSecurity organizations={[TITAN_ORGANIZATION.ADFORM, TITAN_ORGANIZATION.ADFORM_SALES]}>
                  <MenuGroup
                    label="Insights"
                    to={`${SCORPIO.APPLICATION.path}/${SCORPIO.INSIGHTS.path}/${SCORPIO.INSIGHTS.EVENTS.path}`}
                    icon={faChartMixed}
                    selected={group === GROUP.SCORPIO}
                  >
                    <MenuPage
                      label="Events"
                      to={`${SCORPIO.APPLICATION.path}/${SCORPIO.INSIGHTS.path}/${SCORPIO.INSIGHTS.EVENTS.path}`}
                      selected={group === GROUP.SCORPIO && subgroup === PAGE.EVENTS}
                    />
                    <MenuPage
                      label="Campaigns"
                      to={`${SCORPIO.APPLICATION.path}/${SCORPIO.INSIGHTS.path}/${SCORPIO.INSIGHTS.CAMPAIGNS.path}`}
                      selected={group === GROUP.SCORPIO && (subgroup === PAGE.CAMPAIGNS || !subgroup)}
                    />
                    <MenuPage
                      label="Models"
                      to={`${SCORPIO.APPLICATION.path}/${SCORPIO.INSIGHTS.path}/${SCORPIO.INSIGHTS.MODELS.path}`}
                      selected={group === GROUP.SCORPIO && subgroup === PAGE.MODELS}
                    />
                    <PlatformSecurity include organizations={[TITAN_ORGANIZATION.COGNITIV, TITAN_ORGANIZATION.SALES]}>
                      <MenuPage
                        label="Personas"
                        to={`${HYPERION.APPLICATION.path}/${HYPERION.INSIGHTS.path}/${HYPERION.INSIGHTS.PERSONAS.path}`}
                        selected={group === GROUP.SCORPIO && (subgroup === PAGE.PERSONAS || subgroup === PAGE.PERSONA_VERSIONS)}
                      />
                    </PlatformSecurity>
                  </MenuGroup>
                </PlatformSecurity>
                <PlatformSecurity
                  organizations={[TITAN_ORGANIZATION.COGNITIV, TITAN_ORGANIZATION.SALES, TITAN_ORGANIZATION.ADFORM, TITAN_ORGANIZATION.ADFORM_SALES]}
                  include
                >
                  <MenuGroup
                    to={`${ORION.APPLICATION.path}/${ORION.CAMPAIGNS.path}`}
                    label="Contextual"
                    icon={faSquareTerminal}
                    selected={group === GROUP.ORION}
                  >
                    <MenuPage
                      label="Campaigns"
                      to={`${ORION.APPLICATION.path}/${ORION.CAMPAIGNS.path}`}
                      selected={group === GROUP.ORION && (subgroup === PAGE.CAMPAIGNS || !subgroup)}
                    />
                    <MenuPage
                      label="Segments"
                      to={`${ORION.APPLICATION.path}/${ORION.SEGMENTS.path}`}
                      selected={group === GROUP.ORION && subgroup === PAGE.SEGMENTS}
                    />
                    <MenuPage
                      label="Deals"
                      to={`${ORION.APPLICATION.path}/${ORION.DEALS.path}`}
                      selected={group === GROUP.ORION && subgroup === PAGE.DEALS}
                    />
                    <MenuPage
                      label="Domain Lists"
                      to={`${ORION.APPLICATION.path}/${ORION.DOMAIN_LISTS.path}`}
                      selected={group === GROUP.ORION && subgroup === PAGE.DOMAIN_LISTS}
                    />
                  </MenuGroup>
                </PlatformSecurity>
                <PlatformSecurity organizations={[TITAN_ORGANIZATION.COGNITIV, TITAN_ORGANIZATION.ADFORM]} include>
                  <>
                    <div className={classNames(cn.break, cn.high)} />
                    <MenuGroup
                      label="DSP Seat Identifiers"
                      to={`${LIBRA.APPLICATION.path}/${LIBRA.DSP_SEAT_IDENTIFIERS.path}`}
                      icon={faBullhorn}
                      selected={getApplicationPage(2) === PAGE.DSP_SEAT_IDENTIFIERS}
                    />
                  </>
                </PlatformSecurity>
              </div>
            );
          }}
        </AutoSizer>
      </div>
      <div className={cn.break} />
      <div className={cn.break} />
      <MenuAction onClick={switchTheme} label={`${theme === THEME.DARK ? 'Light' : 'Dark'} Mode`} className={cn.switchWrapper}>
        <MenuSwitch checked={theme === THEME.DARK} />
      </MenuAction>
      <span className={classNames(cn.version, { [cn.open]: is_menu_open })}>v {REACT_APP_VERSION}</span>
    </nav>
  );
};
